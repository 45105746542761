<template>

  <v-card fluid
      style="height:100%"
      :style="{backgroundColor:'#c8d2d8'}">
    <v-sheet
      class="rounded"
      v-show="loading == true"
        color="gray lighten-2"
      >
        <v-skeleton-loader
          class="mt-4"
          type="text@2,table-heading,table-thead,table-tbody,table-tfoot"
        ></v-skeleton-loader>
      </v-sheet>
      <v-container
      fluid
      style="height:100%;margin-bottom:80px"
      :style="{backgroundColor:'#c8d2d8'}">
    <v-data-table
      :headers="headers"
      :items="DataAset"
      v-show="loading == false"
      :search="search"
      class="elevation-1"
      :custom-filter="filter"
      :footer-props="{
        showFirstLastPage: false,
        itemsPerPageText: 'Per Page',
      }"
    >
      <template v-slot:top>
  
        <!--desktop-->
        <v-toolbar
          color="#a10115"
          elevation="4"
          dark
          v-show="device == 'Desktop'"
        >
          <v-toolbar-title><span>Data Aset</span></v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-tooltip right color="black" v-show="logged.level == 3 || logged.level == 4">
            <template v-slot:activator="{ on, attrs }">
            <v-icon 
            large
            v-show="logged.level == 3 || logged.level == 4"
             v-bind="attrs"
             v-on="on" 
             class="notecar mr-1 ma-2" 
             @click="ChangeURL('FormDataAset')">
             mdi-car-2-plus</v-icon>
             </template>
             <span style="color: white;"><b>Input</b></span>
             </v-tooltip>
          <v-spacer></v-spacer>
          
          <v-text-field
            prepend-icon="mdi-magnify"
            class="mt-6"
            v-model="search"
            label="Pencarian"
          ></v-text-field>
            <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-toolbar color="red darken-4" dark><span class="text-overline"><v-icon>mdi-alert</v-icon></span></v-toolbar>
              <!-- <v-card-title class="text-h5">Apakah Yakin Akan Menghapus Data ini?</v-card-title> -->
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="BatalHapus">Batal</v-btn>
                <v-btn color="red darken-1" text @click="Hapus">Hapus</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
  
        <!--Mobile-->
        <v-toolbar
          color="#a10115"
          elevation="4"
          dark
          v-show="device == 'Mobile'">
            <v-toolbar-title class="pencarian ml-n1" style="font-size:13px">Data Aset</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon v-show="logged.level > 2 && logged.level < 5" class="mr-1" color="white" @click="ChangeURL('FormDataAset')">mdi-car-2-plus</v-icon>
            <v-spacer></v-spacer>
            <v-text-field
              prepend-icon="mdi-magnify"
              class="mt-6 ml-1"
              v-model="search"
              label="Pencarian"
            ></v-text-field>
          </v-toolbar>
  
      </template>
      <template v-slot:[`item.No`]="{ item }">
        <v-chip class="m">{{item.No}}</v-chip>
      </template>

      <!--tooltip booking-->
      <template v-slot:[`item.Booking`]="{ item }">
        <v-chip class="bt"  v-show="item.isBookingTesDrive == true && item.tesdrive.approve_form_tes_drive == null" @click="OpenAlert(item.tesdrive)">
          <v-tooltip left color="black" v-show="device == 'Desktop'">
          <template v-slot:activator="{ on, attrs }">
          <v-icon  color="#f6f6f6" v-on="on" v-bind="attrs" v-show="device == 'Desktop' && item.isBookingTesDrive == true">mdi-checkbox-marked-circle-outline
            </v-icon>
          </template>
          <span>Booking Tes Drive Jam {{item.tesdrive != null ? item.tesdrive.jam_keluar_kendaraan : ''}} - {{item.tesdrive != null ? item.tesdrive.estimasi_jam_masuk_kendaraan : ''}}</span>
          </v-tooltip>
          <v-icon color="#f6f6f6" v-show="device == 'Mobile'">mdi-checkbox-marked-circle-outline</v-icon>
            <v-icon v-show="item.isBookingTesDrive == false">mdi-checkbox-blank-circle-outline</v-icon>
        </v-chip>

        <v-chip class="bp" v-show="item.isBookingPeminjaman == true && item.peminjaman.approve_peminjaman == null" @click="OpenAlert(item.peminjaman)">
          <v-tooltip left color="black" v-show="device == 'Desktop'">
          <template v-slot:activator="{ on, attrs }">
          <v-icon color="#f6f6f6" v-on="on" v-bind="attrs" v-show="device == 'Desktop' && item.isBookingPeminjaman == true" >mdi-checkbox-marked-circle-outline
            </v-icon>
          </template>
          <span class="mr-1">Booking Peminjaman Jam {{item.peminjaman != null ? item.peminjaman.jam_keluar_kendaraan : ''}} - {{item.peminjaman != null ? item.peminjaman.jam_masuk_kendaraan : ''}}</span>
          </v-tooltip>
          <v-icon color="#f6f6f6" v-show="device == 'Mobile'">mdi-checkbox-marked-circle-outline</v-icon>
            <v-icon v-show="item.isBookingPeminjaman == false">mdi-checkbox-blank-circle-outline</v-icon>
        </v-chip>
      </template>
      <!--end tooltip booking-->

      <!--info formulir dalam template-->
      <template v-slot:[`item.Form`]="{ item }">
        <!--ini untuk dekstop -->
        <v-chip class="mr-1" v-show="device == 'Desktop'">
          <v-btn @click="ToTesDrive(item)" v-show="item.isBookingTesDrive == false && item.jenis_aset == 'Mobil' && item.status_aset != 'NOT READY'" rounded class="tesdrep mx-n3">
            Tes Drive
          </v-btn>
          <v-btn v-show="item.isBookingTesDrive == true || item.jenis_aset != 'Mobil' || item.status_aset == 'NOT READY'" disabled rounded class="mx-n3">
            Tes Drive
          </v-btn>
          </v-chip>
          <span v-show="device == 'Desktop'">|</span>
          <v-chip class=" ml-1" v-show="device == 'Desktop'">
          <v-btn @click="ToPeminjaman(item)" v-show="item.status_aset == 'READY'" rounded class="pinjam mx-n3">
            Peminjaman
          </v-btn>

          <v-btn v-show="item.status_aset == 'NOT READY'" disabled rounded class="mx-n3">
            Peminjaman
          </v-btn>
          
          </v-chip>
          <!-- end of desktop -->

          <!--  ini untuk Mobile -->
          <v-chip class="mr-1" v-show="device == 'Mobile'">
          <v-btn @click="ToTesDrive(item)" v-show="item.isBookingTesDrive == false && item.jenis_aset == 'Mobil' && item.status_aset != 'NOT READY'" rounded class="tesdrep mx-n3">
            <span class="form">Tes Drive</span>
          </v-btn>
          <v-btn v-show="item.isBookingTesDrive == true || item.jenis_aset != 'Mobil' || item.status_aset == 'NOT READY'" disabled rounded class="mx-n3">
            <span class="form">Tes Drive</span>
          </v-btn>
          </v-chip>
          <span v-show="device =='Mobile'">|</span>
          <v-chip class="ml-1" v-show="device == 'Mobile'">
          <v-btn @click="ToPeminjaman(item)" v-show="item.status_aset == 'READY'" rounded class="pinjam mx-n3">
            <span class="form">Peminjaman</span>
          </v-btn>

          <v-btn v-show="item.status_aset == 'NOT READY'" disabled rounded class="mx-n3">
            <span class="form">Peminjaman</span>
          </v-btn>
          </v-chip>
          <!-- end of mobile -->
      </template>
      <!--end info formulir-->

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
        class="btn"
        small
        >
        <v-icon
        small
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
      </v-btn>
      <v-btn
      class="btn2 ml-1"
      small
      >
        <v-icon
        small
          @click="DeleteDialog(item)"
        >
          mdi-delete
        </v-icon>
      </v-btn>
      </template>
      <template v-slot:[`item.status_aset`]="{ item }">
        <v-chip class="kondisiaset" v-show="device == 'Mobile'">
          <span v-show="device == 'Mobile'"
           v-text="item.status_aset == 'READY' ? 'READY' : item.kondisi_aset == 'DAILY' ? 'READY' : 'NOT READY'" class="bold text--truncate"/>
        </v-chip>
        <v-tooltip left color="black" v-show="device == 'Desktop'">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
            v-show="device == 'Desktop'"
              class="ml-n3"
              :color="item.status_aset == 'READY' && item.jenis_aset == 'Mobil' ? '#a10115' :
                      item.status_aset == 'READY' && item.jenis_aset != 'Mobil' ? 'primary' : 'grey'"
              v-bind="attrs"
              v-on="on"
            >
              mdi-car-back
            </v-icon>
          </template>
          <span v-text="item.status_aset == 'READY' ? 'READY' : item.kondisi_aset == 'DAILY' ? 'READY' : 'NOT READY'"></span>
        </v-tooltip>
      </template>
<template v-slot:[`item.kondisi_aset`]="{ item }">                                                                     <!--kirim kondisi tesdrive , beserta data itemnya--> 
        <v-chip class="trac" v-show="item.kondisi_aset == null ? null : item.kondisi_aset.match('TES DRIVE')" @click="OpenKondisi('tesdrive',item)">
        <v-tooltip left color="black" v-show="device == 'Desktop'">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
            class="mt-n1"
            v-bind="attrs"
            v-on="on"
            v-show="device == 'Desktop'"
            color="white"
            >
              mdi-car-traction-control
            </v-icon>
          </template>
          <span v-text="item.kondisi_aset"></span>
        </v-tooltip>

        <v-icon
           v-show="device == 'Mobile'"
            class="mt-n1"
            color="white"
            >
              mdi-car-traction-control
            </v-icon>
        </v-chip>                                                                                       <!--kirim kondisi peminjaman , beserta data itemnya--> 
        <v-chip class="key" v-show="item.kondisi_aset == null ? null : item.kondisi_aset.match('BEROPERASI')" @click="OpenKondisi('peminjaman',item)">
        <!-- gini untuk desktop ajaa -->
          <v-tooltip left color="success" v-show="device == 'Desktop'">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
            class="mt-n1"
            v-bind="attrs"
            v-on="on"
            v-show="device == 'Desktop'"
            color="#FBF2CF"
            >
              mdi-car-key
            </v-icon>
          </template>
          <span v-text="item.kondisi_aset"></span>
        </v-tooltip>

        <v-icon
            v-show="device == 'Mobile'"
            class="mt-n1 ml-n3"
            color="#FBF2CF"
            >
              mdi-car-key
        </v-icon>
      </v-chip>
        <v-chip class="clock" v-show="item.kondisi_aset == null ? null : item.kondisi_aset.match('DAILY')" @click="OpenKondisi('daily',item)">
        <v-tooltip left color="black" v-show="device == 'Desktop'">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
            class="mt-n1"
            v-bind="attrs"
            v-on="on"
            v-show="device == 'Desktop'"
            color="black"
            >
              mdi-car-clock
            </v-icon>
          </template>
          <span v-text="item.kondisi_aset"></span>
        </v-tooltip>
        <v-icon
            class="mt-n1"
            v-show="device == 'Mobile'"
            color="black"
            >
              mdi-car-clock
            </v-icon>
      </v-chip>
        <v-chip class="key" v-show="item.kondisi_aset == null ? null : item.kondisi_aset.match('DIPINJAM')" @click="OpenKondisi('peminjaman',item)">
        <v-tooltip left color="black" v-show="device == 'Desktop'">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
            v-show="device == 'Desktop'"
            class="mt-n1"
            v-bind="attrs"
            v-on="on"
            color="white"            
            >
              mdi-car-key
            </v-icon>
          </template>
          <span v-text="item.kondisi_aset"></span>
        </v-tooltip>
        <v-icon
            class="mt-n1"
             v-show="device == 'Mobile'"
             color="white"
            >
              mdi-car-key
            </v-icon>
        </v-chip>
      </template>
      <template v-slot:no-data>
        <span>Mohon Tunggu</span>
      </template>
    </v-data-table>
    </v-container>

    <!-- Dialog Buka Data Booking -->
    <v-dialog v-model="AlertBooking" width="500px">
    <v-card>
      <v-toolbar dark color="#a10115">
        <span>DATA BOOKING</span>
      </v-toolbar>
      <v-container>
    <v-row v-show="DataBooking.id_form_tes_drive">
      <v-col cols="12">
        <span><b><u>Tes Drive</u></b></span>
      </v-col>
      <v-col cols="12" class="mt-n5">
        <span v-show="device == 'Desktop'"><v-icon class="mr-2" small>mdi-account</v-icon><b>Pengaju Booking : {{DataBooking.penanggung_jawab}}</b></span>
        <span class="nama" v-show="device == 'Mobile'"><v-icon class="mr-2" small>mdi-account</v-icon><b>Pengaju Booking : {{DataBooking.penanggung_jawab}}</b></span>
      <h4></h4>
      <v-divider/>
      <span v-show="device == 'Desktop'"><v-icon class="mr-2" small>mdi-office-building</v-icon><b>Department : {{DataBooking.department != null ? DataBooking.department.nama_department : ''}}</b></span>
      <span class="nama" v-show="device == 'Mobile'"><v-icon class="mr-2" small>mdi-office-building</v-icon><b>Department : {{DataBooking.department != null ? DataBooking.department.nama_department : ''}}</b></span>
      <h4></h4>
      <v-divider/>
      <span v-show="device == 'Desktop'"><v-icon class="mr-2" small>mdi-google-maps</v-icon><b>Tujuan : {{DataBooking.lokasi_tes_drive}}</b></span>
      <span class="nama" v-show="device == 'Mobile'"><v-icon class="mr-2" small>mdi-google-maps</v-icon><b>Tujuan : {{DataBooking.lokasi_tes_drive}}</b></span>
      <h4></h4>
      <v-divider/>
      <span v-show="device == 'Desktop'"><v-icon class="mr-2" small>mdi-clock</v-icon><b>Jam Booking : {{DataBooking.jam_keluar_kendaraan}}</b><span class="ml-8"><v-icon class="mr-2" small>mdi-clock-outline</v-icon><b>Jam Kembali : {{DataBooking.estimasi_jam_masuk_kendaraan}}</b></span></span>
      <span class="nama" v-show="device == 'Mobile'"><span class="out mr-2">out</span><v-icon class="mr-1" small>mdi-calendar</v-icon><b>Tanggal : {{DataBooking.tanggal_pemakaian}}</b><span class="ml-4"><v-icon class="mr-1" small>mdi-clock</v-icon><b>Jam : {{DataBooking.jam_keluar_kendaraan}}</b></span></span>
      <h4></h4>
      <v-divider/>
      <span v-show="device == 'Desktop'"><v-icon class="mr-2" small>mdi-calendar</v-icon><b>Tgl Keluar : {{DataBooking.tanggal_pemakaian}}</b><span class="ml-2"><v-icon class="mr-2" small>mdi-calendar-outline</v-icon><b>Tgl Masuk : {{DataBooking.tanggal_kembali}}</b></span></span>
      <span class="nama" v-show="device == 'Mobile'"><span class="in mr-4">in</span><v-icon class="mr-1" small>mdi-calendar-outline</v-icon><b>Tanggal : {{DataBooking.tanggal_kembali}}</b><span class="ml-4"><v-icon class="mr-1" small>mdi-clock-outline</v-icon><b>Jam : {{DataBooking.estimasi_jam_masuk_kendaraan}}</b></span></span>
    </v-col>
    </v-row>
    <v-row v-show="DataBooking.id_form_peminjaman">
      <v-col cols="12" class="mt-n5">
        <span><b><u>Peminjaman Kendaraan</u></b></span>
      </v-col>
      <v-col cols="12" class="mt-n5">
        <span v-show="device == 'Desktop'"><v-icon class="mr-2" small>mdi-account</v-icon><b>Pengaju Booking : {{DataBooking.penanggung_jawab}}</b></span>
        <span class="nama" v-show="device == 'Mobile'"><v-icon class="mr-2" small>mdi-account</v-icon><b>Pengaju Booking : {{DataBooking.penanggung_jawab}}</b></span>
      <h4></h4>
      <v-divider/>
      <span v-show="device == 'Desktop'"><v-icon class="mr-2" small>mdi-office-building</v-icon><b>Department : {{DataBooking.department != null ? DataBooking.department.nama_department : ''}}</b></span>
      <span class="nama" v-show="device == 'Mobile'"><v-icon class="mr-2" small>mdi-office-building</v-icon><b>Department : {{DataBooking.department != null ? DataBooking.department.nama_department : ''}}</b></span>
      <h4></h4>
      <v-divider/>
      <span  v-show="device == 'Desktop'"><v-icon class="mr-2" small>mdi-google-maps</v-icon><b>Tujuan : {{DataBooking.tujuan}},</b><span style="color:forestgreen"><b> ({{DataBooking.keperluan}})</b></span></span>
      <span class="nama" v-show="device == 'Mobile'"><v-icon class="mr-2" small>mdi-google-maps</v-icon><b>Tujuan : {{DataBooking.tujuan}},</b><span style="color:forestgreen"><b> ({{DataBooking.keperluan}})</b></span></span>
      <h4></h4>
      <v-divider/>
      <span  v-show="device == 'Desktop'"><v-icon class="mr-2" small>mdi-clock</v-icon><b>Jam Booking : {{DataBooking.jam_keluar_kendaraan}}</b><span class="ml-8"><v-icon class="mr-2" small>mdi-clock-outline</v-icon><b>Jam Kembali : {{DataBooking.jam_masuk_kendaraan}}</b></span></span>
      <span class="nama" v-show="device == 'Mobile'"><span class="out">out</span><v-icon class="mr-1 ml-2" small>mdi-calendar</v-icon><b>Tanggal : {{DataBooking.tgl_peminjaman}}</b><v-icon class="mr-1 ml-2" small>mdi-clock</v-icon><b>Jam : {{DataBooking.jam_keluar_kendaraan}}</b></span>
      <h4></h4>
      <v-divider/>
      <span v-show="device == 'Desktop'"><v-icon class="mr-2" small>mdi-calendar</v-icon><b>Tgl Masuk : {{DataBooking.tgl_peminjaman}}</b><span class="ml-2"><v-icon class="mr-2" small>mdi-calendar-outline</v-icon><b>Tgl Masuk : {{DataBooking.tgl_pengembalian}}</b></span></span>
      <span class="nama" v-show="device == 'Mobile'"><span class="in mr-2">in</span><v-icon class="mr-1 ml-2" small>mdi-calendar-outline</v-icon><b>Tanggal : {{DataBooking.tgl_pengembalian}}</b><v-icon class="mr-1 ml-2" small>mdi-clock-outline</v-icon><b>Jam : {{DataBooking.jam_masuk_kendaraan}}</b></span>
    </v-col>
    </v-row>
  </v-container>
  </v-card>
  </v-dialog>
  <!-- end of dialog Data Booking -->

  <!-- Dialog Data Kondisi peminjaman -->
  <v-dialog v-model="DialogKondisi" width="500px" height="400px">
    <v-card>
      <v-toolbar dark color="#a10115">
        <span class="text-capitalize">Data {{Kondisi}}</span>
      </v-toolbar>
      <v-container>
        <v-row>
          <v-col cols="12">
            <span v-show="device == 'Desktop'"><v-icon class="mr-2" small>mdi-account</v-icon><b>Penanggung Jawab : {{DataKondisi.penanggung_jawab}}</b></span>
            <span class="nama" v-show="device == 'Mobile'"><v-icon class="mr-2" small>mdi-account</v-icon><b>Penanggung Jawab : {{DataKondisi.penanggung_jawab}}</b></span>
            <h4></h4>
            <v-divider/>
            <span v-show="device == 'Desktop'"><v-icon class="mr-2" small>mdi-office-building</v-icon><b>Department : {{DataKondisi.department != null ? DataKondisi.department.nama_department : ''}}</b></span>
            <span class="nama" v-show="device == 'Mobile'"><v-icon class="mr-2" small>mdi-office-building</v-icon><b>Department : {{DataKondisi.department != null ? DataKondisi.department.nama_department : ''}}</b></span>
            <h4></h4>
            <v-divider/>
            <span v-show="device == 'Desktop'"><v-icon class="mr-2" small>mdi-google-maps</v-icon><b>Tujuan : {{Kondisi == 'peminjaman' || Kondisi == 'daily' ? DataKondisi.tujuan : DataKondisi.lokasi_tes_drive}},</b><span style="color:forestgreen"><b> ({{Kondisi == 'peminjaman' || Kondisi == 'daily' ? DataKondisi.keperluan : Kondisi == 'daily' ? DataKondisi.keperluan : 'Tes Drive'}})</b></span></span>
            <span class="nama" v-show="device == 'Mobile'"><v-icon class="mr-2" small>mdi-google-maps</v-icon><b>Tujuan : {{Kondisi == 'peminjaman' || Kondisi == 'daily' ? DataKondisi.tujuan : DataKondisi.lokasi_tes_drive}},</b><span style="color:forestgreen"><b> ({{Kondisi == 'peminjaman' || Kondisi == 'daily' ? DataKondisi.keperluan : Kondisi == 'daily' ? DataKondisi.keperluan : 'Tes Drive'}})</b></span></span>
            <h4></h4>
            <v-divider/>                                                       <!-- Jika Kondisi Pinjam ini yg di klik peminjaman maka tampilkan tgl_peminjaman , Jika Bukan Tampilkan Tgl_tesdrive-->
            <span v-show="device == 'Desktop'"><v-icon class="mr-2" small>mdi-clock</v-icon><b>Jam Keluar : {{Kondisi == 'peminjaman' || Kondisi == 'daily' ? DataKondisi.jam_keluar_kendaraan : DataKondisi.jam_keluar_kendaraan}}</b><span class="ml-11"><v-icon class="mr-2" small>mdi-clock-outline</v-icon><b>Jam Masuk : {{Kondisi == 'peminjaman' || Kondisi == 'daily' ? DataKondisi.jam_masuk_kendaraan : DataKondisi.estimasi_jam_masuk_kendaraan}}</b></span></span>
            <h4 v-show="device == 'Desktop'"></h4>
            <v-divider v-show="device == 'Desktop'"/> 
            <span v-show="device == 'Desktop'" class="mr-2"><v-icon class="mr-2" small>mdi-calendar</v-icon><b>Tgl Keluar : {{Kondisi == 'peminjaman' || Kondisi == 'daily' ? DataKondisi.tgl_peminjaman : DataKondisi.tanggal_pemakaian}}</b><span class="ml-2"><v-icon class="mr-2" small>mdi-calendar-outline</v-icon><b>Tgl Masuk : {{Kondisi == 'peminjaman' || Kondisi == 'daily'?  DataKondisi.tgl_pengembalian : DataKondisi.tanggal_kembali}}</b></span></span>
            <span class="nama mr-2" v-show="device == 'Mobile'"><span class="out">out </span><v-icon class="mr-1 ml-1" small>mdi-calendar</v-icon><b>Tanggal : {{Kondisi == 'peminjaman' || Kondisi == 'daily' ? DataKondisi.tgl_peminjaman : DataKondisi.tanggal_pemakaian}}</b><span class="nama ml-2"><v-icon class="mr-1" small>mdi-clock</v-icon><b>Jam : {{Kondisi == 'peminjaman' || Kondisi == 'daily' ? DataKondisi.jam_keluar_kendaraan : DataKondisi.jam_keluar_kendaraan}}</b></span></span>
            <h4  v-show="device == 'Mobile'"></h4>
            <v-divider  v-show="device == 'Mobile'"/>
            <span v-show="device == 'Mobile'" class="nama"><span class="in">in </span><v-icon class="mr-1 ml-3" small>mdi-calendar-outline</v-icon><b>Tanggal : {{Kondisi == 'peminjaman' || Kondisi == 'daily'?  DataKondisi.tgl_pengembalian : DataKondisi.tanggal_kembali}}</b><span class="nama ml-2"><v-icon class="mr-1" small>mdi-clock-outline</v-icon><b>Jam : {{Kondisi == 'peminjaman' || Kondisi == 'daily' ? DataKondisi.jam_masuk_kendaraan : DataKondisi.estimasi_jam_masuk_kendaraan}}</b></span></span>
          </v-col>
        </v-row>
      </v-container>
      </v-card>
  </v-dialog>
  <!-- end of dialog data kondisi peminjaman-->

    <!-- Dialog Data Kondisi peminjaman -->
    <!-- <v-dialog v-model="DialogKondisi" width="350px" height="400px">
    <v-card>
      <v-toolbar dark color="#a10115">
        <span class="text-capitalize">Data {{KondisiTesDrive}}</span>
      </v-toolbar>
      <v-container>
        <v-row>
          <v-col cols="12">
            <span>Penanggung Jawab : {{DataKondisi.penanggung_jawab}}</span>
            <h4></h4>
            <span>Department : {{DataKondisi.department != null ? DataKondisi.department.nama_department : ''}}</span>
            <h4></h4>
            <span>Tgl Masuk : {{KondisiTesDrive == 'peminjaman' ? DataKondisi.tgl_pengembalian : DataKondisi.tanggal_kembali}}</span>
          </v-col>
        </v-row>
      </v-container>
      </v-card>
  </v-dialog> -->
  <!-- end of dialog data kondisi peminjaman-->

  </v-card>
  </template>
  <script>
  import API from "@/services/http";
    export default {
      data: () => ({
        dialog: false,
        device: null,
        AlertBooking:false,
        DataBooking:[],
        logged:[],
        loading:true,
        JmlhAset:'',
        dialogDelete: false,
        headers: [
          {
            text: 'No',
            align: 'start',
            value: 'No',
          },
          {
            text: 'Jenis',
            align: 'start',
            value: 'jenis_aset',
          },
          { text: 'Nama', value: 'nama_aset' },
          { text: 'Warna', value: 'warna' },
          { text: 'Nomor Polisi', value: 'no_plat' },
          { text: 'BBM', value: 'BBM' },
          { text: 'KM', value: 'KM' },
          { text: 'Status Aset', value: 'status_aset' ,align: 'center',},
          { text: 'Kondisi Aset', value: 'kondisi_aset',align: 'center', },
          { text: 'Booking', value: 'Booking',align: 'center', },
          { text: 'Info Formulir', value: 'Form',align: 'center', },
        ],
        desserts: [],
        DataAset:[],
        DialogKondisi:false,
        Kondisi:null,
        KondisiTesDrive:null,
        DataKondisi:[],
        search:'',
        editedIndex: -1,
      }),
  
      computed: {
        formTitle () {
          return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
        },
      },
  
      watch: {
        //
      },
  
      created () {
        // this.$loading(true)
        this.logged = this.$SetLog()
        this.device = this.$device
        this.aksicek()
        this.getDataAset()
      },
      mounted(){
        //
      },
  
       methods: {
      aksicek(){
        if(this.logged.level == 4 || this.logged.level == 3){
          this.headers.push({ text: 'Actions', value: 'actions', sortable: false , align: 'center',})
        }
      },
      editItem(x){
        this.$Store(x)
        console.log(x)
        this.ChangeURL('FormDataAset')
      },
      DeleteDialog(x){
          this.$Store(x)
          this.dialogDelete = true
      },
      ToTesDrive(i){
        this.$Store(i)
        this.ChangeURL('FormTesDrive')
      },
      ToPeminjaman(i){
        this.$Store(i)
        this.ChangeURL('FormPeminjaman')
      },
      BatalHapus(){
        this.$Store(null)
        this.dialogDelete = false
      },
      OpenAlert(x){
        this.AlertBooking = true
        this.DataBooking = x
      },
      OpenKondisi(x,item){
        console.log(item)
        this.DialogKondisi = true
        this.Kondisi = x//x x ini adalah penentu kondisi
        this.DataKondisi = item[x] //ini untuk isi data nya
        console.log('ee',this.DataKondisi)
        // if(this.DataKondisi.keperluan == 'DAILY'){
        //   this.DataKondisi.department = this.DataKondisi.daily.department
        // }
      },
      Hapus(){
        this.dialogDelete = false
        let z = this.$CekStore() == null ? [] : this.$CekStore()
        this.deleteItem(z)
        this.$Store(null)
      },
      deleteItem(x){
        API.delete('aset/'+x.id_aset).then(c=>{
          console.log(c)
          this.getDataAset()
          this.log(x)
          this.$Toast('success','Data Aset Di Hapus')
        })
      },
      log(x){
        let data = {
          User:this.$UserLogged(),
          Status:'Menghapus Data Aset '+x.jenis_aset+' '+x.nama_aset+' '+x.no_plat
        }
        API.post('log',data).then(c=>{
          console.log(c)
        })
      },
      ChangeURL(x){
        this.$ChangeURL(x)
      },
      filter(value, search){
        search = search.toString().toLocaleLowerCase()
        return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      },
      getDataAset(){
        this.Seen()
        API.get("/asetfilter").then(x=>{
        let i = 1
        x.data.forEach(z => {
          z.No =i
          i++
        });
        this.DataAset = x.data
        this.loading = false
        this.$loading(false)
        })
      },
      Seen(){
        API.get('seen/aset/'+this.logged.id_user)
        },
      },
    }
  </script>
  <style>
  .aset{
    background-color: #a10115 !important;
    margin-right: 2px;
    border:2px solid #ffffff75 !important;
  }
  .pencarian{
    width: 150px;
  }
  .notecar{
    font-size:35px !important;
  }
  .form{
    font-size:9px !important;
  }
  .nama{
    font-size: small;
  }
  .in{
    font-size:small;
    color:green;
  }
  .out{
    font-size:small;
    color:#a10115;
  }
  
  .btn{
    background-color: #43658B !important;
    color: white !important;
  }
  .btn2{
    background-color: #a10115 !important;
    color: white !important;
  }
  .bt{
    background-color: #43658B !important;
  }
  .bp{
    background-color: #a10115 !important;
  }
  .trac{
    background-color:  #78B0A0 !important;
  }
  .key{
    background-color: #BE7575 !important;
  }
  .clock{
    background-color:  #FAFF00 !important;
  }
  .pinjam{
    background-color: #BE7575 !important;
    color: #F6F6F6 !important;
  }
  .tesdrep{
    background-color: #78B0A0 !important;
    color: #F6F6F6 !important;
  }
  .status{
    background-color: black !important;
  }
  .kondisiaset{
    background-color: #43658B !important;
    color: white !important;
  }
  </style>
  